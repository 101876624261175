import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Inner from '../../components/templates/Inner';
import { Title, Text, Flex, Box } from '../../components/atoms';
import { designTips as nav } from '../../nav';
import img01 from '../../assets/img/design-tips/design-tips/01.svg';
import img02 from '../../assets/img/design-tips/design-tips/02.svg';
import img03 from '../../assets/img/design-tips/design-tips/03.svg';
import img04 from '../../assets/img/design-tips/design-tips/04.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Inner nav={nav} title="Design tips" mdxType="Inner">
  <Title as="h1" mb={64} mdxType="Title">Design principles</Title>
  <Flex my={40} mdxType="Flex">
    <Box width={320} mr={208} mdxType="Box">
      <Text mdxType="Text">
        <img src={img01} alt="" />
      </Text>
      <Title size="h5" mb="s" mdxType="Title">Consistency</Title>
      <Text mdxType="Text">When similar elements look consistent and display the functions they perform, usability thrives.</Text>
    </Box>
    <Box width={320} mr={112} mdxType="Box">
      <Text mdxType="Text">
        <img src={img02} alt="" />
      </Text>
      <Title size="h5" mb="s" mdxType="Title">Simplicity</Title>
      <Text mdxType="Text">Clarity results in more efficient user interfaces (UIs) to help users perform their tasks fast and easy.</Text>
    </Box>
  </Flex>
  <Flex mt={40} mdxType="Flex">
    <Box width={320} mr={208} mdxType="Box">
      <Text mdxType="Text">
        <img src={img03} alt="" />
      </Text>
      <Title size="h5" mb="s" mdxType="Title">Reinventing Do/Don’t</Title>
      <Text mdxType="Text">Using top-notch design practices to channel a set of patterns into a simple, yet powerful UI.</Text>
    </Box>
    <Box width={320} mr={112} mdxType="Box">
      <Text mdxType="Text">
        <img src={img04} alt="" />
      </Text>
      <Title size="h5" mb="s" mdxType="Title">Less is More</Title>
      <Text mdxType="Text">Get the most from UI. Limit the users from having to navigate unnecessary content and let them focus on what matters.</Text>
    </Box>
  </Flex>
    </Inner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      